import { useMemo ,useEffect,useState} from 'react';
import { useTranslation } from 'react-i18next';
import SvgIcon from '@mui/material/SvgIcon';

import HomeSmileIcon from 'src/icons/untitled-ui/duocolor/home-smile';
import CalendarIcon from 'src/icons/untitled-ui/duocolor/calendar';
import { paths } from 'src/paths';
import { useAuth } from 'src/hooks/use-auth';

export const useSections =  () => {
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState(false);
  const auth = useAuth();
  useEffect(async () => {
   
    const token=await auth.user.user.getIdToken();
    console.log(auth.user.email);
  const  response = await fetch(`${process.env.REACT_APP_API_URL}api/calender/permissions?email=${auth.user.email}`,{
      headers: {
        authorization: `Bearer ${token}`  
      }
    });
    const result = await response.json();
    setPermissions(result.permissions);
  }, []);
  return useMemo(() => {
    let sections = [
      {
        items: [
          {
            title: 'סקירה כללית',
            path: paths.dashboard.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
          {
            title: 'לוח שנה',
            path: paths.dashboard.calendar,
            icon: (
              <SvgIcon fontSize="small">
                <CalendarIcon />
              </SvgIcon>
            ),
          },
          {
            title: 'שיחות',
            path: paths.dashboard.customers.index,
            icon: (
              <SvgIcon fontSize="small">
                <CalendarIcon />
              </SvgIcon>
            ),
          },
          {
            title: 'פעולות משתמשים',
            path: paths.dashboard.activity,
            icon: (
              <SvgIcon fontSize="small">
                <CalendarIcon />
              </SvgIcon>
            ),
          },
          // הגדרות תתווסף רק אם יש הרשאות
        ],
      }
    ];

    // הוספת הגדרות אם יש הרשאות
    if (permissions) {
      sections[0].items.push({
        title: 'הגדרות',
        path: paths.dashboard.account,
        icon: (
          <SvgIcon fontSize="small">
            <HomeSmileIcon />
          </SvgIcon>
        )
      },
      {
        title: 'רשימת תפוצה בווטצאפ',
        path: paths.dashboard.walist,
        icon: (
          <SvgIcon fontSize="small">
            <CalendarIcon />
          </SvgIcon>
        ),
      },
      );
    }

    if (permissions) {
      sections.push({
        subheader: "בוט טלפוני",
        items: [
          {
        title: 'הוראות לשיחה',
        path: paths.dashboard.botprompt,
        icon: (
          <SvgIcon fontSize="small">
            <HomeSmileIcon />
          </SvgIcon>
        )
      },
      {
        title: 'שיחות',
        path: paths.dashboard.botcalls,
        icon: (
          <SvgIcon fontSize="small">
            <HomeSmileIcon />
          </SvgIcon>
        )
      }
    ]}
      );
    }

    return sections;
  }, [t,permissions]);
};
